.aside_inner {
  > section {
    margin-left: 10px; } }

.cool {
  background-color: #5783db;
  & + & {
    background-color: #5783db;
    color: var(--light); } }

.nav-green {
  background-color: var(--theme) !important;
  color: var(--light) !important;

  & + & {
    background-color: var(--theme) !important;
    color: var(--light) !important; } }


.spaced-right-10 {
  margin-right: 10px; }

.no_font_weight {
  font-weight: initial; }

.subsection_content {
  &.collapsed {
    display: none; }

  &.expanded {
    display: block; }

  &.always-visible {
    /* top-level container is always shown, no toggle needed */
    display: block; } }

.submenu-toggle {
  position: relative;
  margin-left: 0.5rem;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;

  &::before {
    content: "▸";
    color: #6ab825; }

  &.open::before {
    content: "▾";
    color: #6ab825; } }







