
html {
  --info-icon: url('https://docs.ezoic.com/icons/info.svg');
  --sun-icon: url('https://docs.ezoic.com/icons/sun.svg');
  --moon-icon: url('https://docs.ezoic.com/icons/moon.svg');
  --next-icon: url('https://docs.ezoic.com/icons/next.svg'); }
@import "variables";
@import "base";
@import "nav";
@import "components";
@import "mermaid";
@import "blog";
@import "utils";
@import "syntax";
@import "fonts";
@import "chart";
@import "custom";
@import "mermaid";
